import type { ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { Side } from 'qonto/react/graphql';
import { BaseCell } from '../base-cell';
import { CopyButton } from '../../buttons';
import styles from './styles.strict-module.css';

interface AmountCellProps {
  amount: {
    currency: string;
    value: string;
  };
  type?: Side;
}

export function AmountCell({ amount, type }: AmountCellProps): ReactNode {
  const { currency, value } = amount;
  const { formatNumber } = useIntl();
  const isTransaction = Boolean(type);
  const amountNumber = Number(`${type === Side.Debit ? '-' : ''}${value}`);

  const isNegative = amountNumber < 0;
  const positiveHighlight = isTransaction && !isNegative;
  const negativeHighlight = !isTransaction && isNegative;
  let formattedAmount = formatNumber(amountNumber, {
    currency,
    style: 'currency',
  });

  if (isNegative) {
    formattedAmount = formattedAmount.replace('-', '-\xA0'); // space the negative sign
  }

  return (
    <BaseCell actionSlot={<CopyButton text={value} />} align="right">
      <span
        data-testid="amount-cell"
        data-test-positive-highlight={positiveHighlight}
        data-test-negative-highlight={negativeHighlight}
        className={cx(styles.amount, positiveHighlight && styles.positiveHighlight)}
      >
        {positiveHighlight ? <span className={styles['plus-sign']}>+ </span> : null}
        {formattedAmount}
      </span>
    </BaseCell>
  );
}
