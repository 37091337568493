import React, { type ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';
import { CategoryAvatar } from './category-avatar';

interface CategoryIconProps {
  color: string;
  icon: string | null;
}

export function CategoryIcon({ color, icon }: CategoryIconProps): ReactNode {
  return (
    <div className={cx(styles['category-icon-container'])}>
      {icon ? (
        <CategoryAvatar color={color} icon={icon} />
      ) : (
        <div
          style={{
            backgroundColor: `var(${color})`,
          }}
          className={cx(styles['category-color-dot'])}
          data-testid="category-color-dot"
        />
      )}
    </div>
  );
}
