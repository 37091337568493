import { createContext, useContext, type ReactNode } from 'react';

interface CellContext {
  transactionId: string;
}

export const CellContext = createContext<CellContext | null>(null);

export function CellProvider({
  children,
  transactionId,
}: {
  children: ReactNode;
  transactionId: string;
}): ReactNode {
  return <CellContext.Provider value={{ transactionId }}>{children}</CellContext.Provider>;
}

export function useCellContext(): CellContext {
  const context = useContext(CellContext);
  if (!context) {
    throw new Error('useCellContext must be used within a CellProvider');
  }

  return context;
}

export const cellContextManager = {
  useCellContext,
};
