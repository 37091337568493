import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

interface ChooseCardInviteePrice {
  price: number;
}

export function ChooseCardInviteePrice({ price }: ChooseCardInviteePrice): ReactNode {
  return (
    <p data-test-price>
      {price ? (
        <>
          <FormattedNumber
            value={price}
            currency="EUR"
            style="currency"
            currencyDisplay="symbol"
            maximumFractionDigits={0}
          />
          <span className="body-1">
            {` `}
            <FormattedMessage id="cards.steps.choose-card.pricing-period" />
          </span>
        </>
      ) : (
        <FormattedMessage id="cards.steps.choose-card.included" />
      )}
    </p>
  );
}
