import { type ReactNode } from 'react';
import cx from 'clsx';
import { LightningOutlined } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface LabelTileProps {
  title: string;
  body: string;
  ctaText: string;
  onClick: () => void;
}

export function LabelTile({ title, body, ctaText, onClick }: LabelTileProps): ReactNode {
  return (
    <article className={cx(styles['label-tile'])} data-testid="label-tile">
      <h4 className={cx([styles.title, 'body-1'])} data-testid="title">
        <LightningOutlined className="mr-8" />
        {title}
      </h4>
      <p className="mb-16 body-2" data-testid="body">
        {body}
      </p>
      <footer className={cx(styles.actions)}>
        <button
          type="button"
          className="btn btn--primary btn--small body-1"
          onClick={onClick}
          data-testid="cta"
        >
          {ctaText}
        </button>
      </footer>
    </article>
  );
}
