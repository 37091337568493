import { useState, type ReactNode } from 'react';
import cx from 'clsx';
import type { Uppy } from '@uppy/core';
import { useIntl } from 'react-intl';
import useUppyState from '@uppy/react/lib/useUppyState';
import { Spinner } from '@repo/design-system-kit';
import { Popover, PopoverHeader, PopoverSection } from 'qonto/react/components/table-v2/popover';
import type { Attachment } from 'qonto/react/graphql';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import type { DataWithIconProps } from 'qonto/react/components/table-v2/cells/data-with-icon-cell/data-with-icon-cell';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { useUnlinkTransactionAttachment } from 'qonto/react/hooks/mutations/use-unlink-attachment';
import type { Body, Meta } from 'qonto/react/hooks/use-attachments-uploader';
import { AttachmentUploader } from 'qonto/react/components/table-v2/attachment-uploader';
import { AttachmentOutlined } from 'qonto/react/assets/icons/attachment-outlined';
import { AttachmentViewer } from '../../../attachment-viewer';
import styles from './styles.strict-module.css';
import { AttachmentItem } from './attachment-item';
import { AttachmentItemUploading } from './attachment-item-uploading';

interface AttachmentCellPopoverProps {
  attachments: Attachment[];
  title?: string;
  icon?: ReactNode;
  type?: DataWithIconProps['type'];
  uppy: Uppy<Meta, Body>;
  isUploading: boolean;
}

export function AttachmentCellPopover({
  attachments,
  title,
  icon,
  type,
  uppy,
  isUploading,
}: AttachmentCellPopoverProps): ReactNode {
  const [showPreview, setShowPreview] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState<Attachment | null>(null);
  const { transactionId } = cellContextManager.useCellContext();
  const { mutate: deleteAttachment } = useUnlinkTransactionAttachment(transactionId);
  const { formatMessage } = useIntl();
  const uploadingFiles = useUppyState(uppy, state => state.files);

  const handleDownload = (attachment: Attachment): void => {
    window.open(attachment.downloadUrl, '_blank');
  };

  const handleCancelUpload = (fileId: string): void => {
    uppy.removeFile(fileId);
  };

  const getFileName = (fileName: string | undefined): string => {
    if (!fileName) {
      return '';
    }
    return fileName.length > 20 ? `⋅⋅ ${fileName.slice(-20)}` : fileName;
  };

  const getPopoverHeaderMeta = (): {
    title: string;
    subtitle?: string;
    type?: 'info' | 'error' | undefined;
    icon: ReactNode;
  } => {
    if (isUploading) {
      return {
        title: formatMessage({
          id: 'transactions.table.attachments.status.title.uploading',
        }),
        icon: <Spinner color="primary-a" />,
      };
    }

    if (attachments.length === 0) {
      if (!title) {
        throw new Error('title prop is required when there are no attachments');
      }

      return {
        title,
        icon,
        type,
      };
    }

    return {
      title: attachments.length.toString(),
      icon: <AttachmentOutlined />,
    };
  };

  return (
    <Popover>
      <PopoverHeader>
        <DataWithIconCell {...getPopoverHeaderMeta()} />
      </PopoverHeader>

      {attachments.length || Object.values(uploadingFiles).length ? (
        <PopoverSection className={styles.section}>
          <div className={cx(styles.container)}>
            {Object.values(uploadingFiles).map(({ id, name, progress }) => (
              <AttachmentItemUploading
                key={id}
                fileName={getFileName(name)}
                onCancel={() => {
                  handleCancelUpload(id);
                }}
                progress={progress.percentage ?? 0}
              />
            ))}
            {attachments.map(attachment => {
              const { id, file } = attachment;
              return (
                <AttachmentItem
                  key={id}
                  fileName={getFileName(file?.name)}
                  onFilePreview={() => {
                    setSelectedAttachment(attachment);
                    setShowPreview(true);
                  }}
                  onDownload={() => {
                    handleDownload(attachment);
                  }}
                  onDelete={() => {
                    deleteAttachment(id);
                  }}
                />
              );
            })}
          </div>
        </PopoverSection>
      ) : null}
      <PopoverSection className={styles.section}>
        <div className={cx(styles.container)}>
          <AttachmentUploader
            uppy={uppy}
            buttonLabel={formatMessage({
              id:
                attachments.length === 0
                  ? 'transactions.table.attachments.popover.action.add'
                  : 'transactions.table.attachments.popover.action.add-more',
            })}
          />
        </div>
      </PopoverSection>
      {selectedAttachment ? (
        <AttachmentViewer
          attachment={selectedAttachment}
          isOpen={showPreview}
          onOpenChange={setShowPreview}
        />
      ) : null}
    </Popover>
  );
}
