import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import { BaseCell } from '../base-cell';

interface DateCellProps {
  date?: string | null;
}

function DateCellRoot({ date }: DateCellProps): ReactNode {
  const { locale } = useIntl();

  const formattedDate = dateToken({
    date: date ?? '',
    locale,
    token: 'date-year-s',
  });

  return (
    <div data-testid="date-cell">
      <span>{formattedDate || '-'}</span>
    </div>
  );
}

export function DateCell({ date }: DateCellProps): ReactNode {
  return (
    <BaseCell>
      <DateCellRoot date={date} />
    </BaseCell>
  );
}
