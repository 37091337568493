import { type ReactElement } from 'react';

/**
 * React intl doesn't support HTML content in the message props. It would require using a different system for our translations with tags
 * @deprecated This is a temporary function to translate content that is not yet translated in the new system
 * @param content - The rich content to render
 * @returns A React element with the rendered content
 */
export function renderLegacyHTMLTranslatedContent(content: string): ReactElement {
  return <div dangerouslySetInnerHTML={{ __html: content }} />;
}
