import { type ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Link } from '@repo/design-system-kit';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styles from './styles.strict-module.css';

interface Amount {
  value: number;
  currency: string;
}

interface PaymentDetailsProps {
  monthlyAmount: Amount;
  monthlyInterestRate: number;
  faq: {
    label: string;
    link: string;
  };
}

export function PaymentDetails({
  monthlyAmount,
  monthlyInterestRate,
  faq,
}: PaymentDetailsProps): ReactNode {
  const segment = useEmberService('segment');

  const handleLinkClick = (): void => {
    segment.track('pay-later-faq_clicked', {
      origin: 'toggle_on',
    });
  };

  return (
    <div>
      <div className={styles['details-container']}>
        <dl className={styles['payment-detail']}>
          <dt data-testid="monthly-amount-label">
            <FormattedMessage id="transfers.sepa.new.summary.pay-later.toggle.monthly-amount" />
          </dt>
          <dd className="body-1" data-testid="monthly-amount-value">
            <FormattedNumber
              value={monthlyAmount.value}
              style="currency"
              currency={monthlyAmount.currency}
              minimumFractionDigits={0}
              maximumFractionDigits={2}
            />
          </dd>
        </dl>
        <dl className={styles['payment-detail']}>
          <dt data-testid="monthly-interest-label">
            <FormattedMessage id="transfers.sepa.new.summary.pay-later.toggle.monthly-interest" />
          </dt>
          <dd className="body-1" data-testid="monthly-interest-value">
            <FormattedNumber
              value={monthlyInterestRate / 100}
              style="percent"
              minimumFractionDigits={0}
              maximumFractionDigits={2}
            />
          </dd>
        </dl>
      </div>
      <Link
        href={faq.link}
        target="_blank"
        rel="noopener noreferrer"
        onPress={handleLinkClick}
        data-testid="faq-link"
      >
        {faq.label}
      </Link>
    </div>
  );
}
