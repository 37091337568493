import cx from 'clsx';
import { type CountryCode, Flag, Spinner } from '@repo/design-system-kit';
import { type ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { formatAccountInformation } from 'qonto/utils/international-out/format';
import type { Beneficiary } from 'qonto/services/international-out/types';
import styles from './styles.strict-module.css';

interface InformationProps {
  beneficiary: Omit<Beneficiary, 'branchIdentifier'>;
  isLoading?: boolean;
}

export function Information({ beneficiary, isLoading = false }: InformationProps): ReactNode {
  const { formatDisplayName, formatMessage, messages } = useIntl();

  const formattedAccountInformation = formatAccountInformation({
    accountIdentifier: beneficiary.accountIdentifier,
    bankIdentifier: beneficiary.bankIdentifier,
  });

  const paymentType = useMemo(() => {
    const translationKey = `international-out.dynamic-form.payment-type.${beneficiary.paymentType}`;

    if (messages[translationKey]) {
      return formatMessage({ id: translationKey });
    }

    return null;
  }, [beneficiary.paymentType, formatMessage, messages]);

  return (
    <div className={cx(styles.wrapper)}>
      <div>
        <span className={cx(styles.name, 'body-1 mr-8')} data-testid="name">
          {beneficiary.name}
        </span>

        <div className={cx(styles.account, 'body-2 mr-16')}>
          {paymentType ? (
            <span className={cx(styles['payment-type'], 'body-2')} data-testid="payment-type">
              {paymentType}:{' '}
            </span>
          ) : null}
          <span className={cx(styles['account-number'], 'body-2')} data-testid="account-numbers">
            {formattedAccountInformation}
          </span>
        </div>
      </div>

      <div className={cx(styles.destination)}>
        <Flag
          code={beneficiary.country as CountryCode}
          size="small"
          aria-label={formatDisplayName(beneficiary.country, { type: 'region' })}
          data-testid="country"
        />
        <div className={cx(styles['currency-wrapper'])}>
          {isLoading ? (
            <Spinner color="primary-a" size="small" data-testid="spinner" />
          ) : (
            <span className={cx(styles.currency)} data-testid="currency">
              {beneficiary.currency}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
