import React, { useCallback, useEffect, useState, type Key, type ReactNode } from 'react';
import {
  Button,
  Label,
  ListBox,
  Popover,
  Select,
  Collection,
  Section,
  Header,
} from 'react-aria-components';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { SkeletonLoader } from '@repo/design-system-kit';
import type {
  CashflowActiveCategoryId,
  CashflowCategories,
} from 'qonto/react/models/cash-flow-category';
import { ChevronBottomOutlined } from 'qonto/react/assets/icons';
import { getFlatCategories } from '../utils/cash-flow-categories';
import styles from './styles.strict-module.css';
import { Category } from './category';
import { ParentCategory } from './parent-category';

interface CashflowCategorySelectorProps {
  categories: CashflowCategories;
  activeCategoryId: CashflowActiveCategoryId;
  onSelectionChange: (categoryId: CashflowActiveCategoryId) => void;
  isLoading: boolean;
}
export function CashflowCategorySelector({
  categories = {
    inflow: [],
    outflow: [],
  },
  activeCategoryId = null,
  onSelectionChange,
  isLoading,
}: CashflowCategorySelectorProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');
  const { inflow, outflow } = categories;

  const flatCategories = getFlatCategories(categories);
  const hasCategories = flatCategories.length > 0;
  const categoriesData = [
    {
      id: 'inflow',
      name: formatMessage({ id: 'cash-flow-categories.group-title.inflows' }),
      categories: inflow,
    },
    {
      id: 'outflow',
      name: formatMessage({ id: 'cash-flow-categories.group-title.outflows' }),
      categories: outflow,
    },
  ];

  const [selectedCategoryId, setSelectedCategoryId] = useState(activeCategoryId);
  useEffect(() => {
    setSelectedCategoryId(activeCategoryId);
  }, [activeCategoryId]);

  const handleOpenChange = (isOpen: boolean): void => {
    if (isOpen) {
      segment.track('transaction_categories-opened');
    }
  };

  const handleSelectionChange = (key: Key): void => {
    const newCategoryId = key === selectedCategoryId ? null : (key as string);
    setSelectedCategoryId(newCategoryId);
    onSelectionChange(newCategoryId);

    const category = flatCategories.find(({ id }) => id === key);
    segment.track('transaction_category-selected', {
      category: category?.nameKey ?? category?.name,
      selection: newCategoryId ? 'selected' : 'unselected',
    });
  };

  const displaySelectedCategory = (): ReactNode => {
    const selectedCategory = flatCategories.find(category => category.id === selectedCategoryId);
    if (selectedCategory) return <Category item={selectedCategory} emphasized />;

    return (
      <span className={cx(styles['select-placeholder'])}>
        {hasCategories
          ? formatMessage({ id: 'transactions.sidebar.cash-flow-categories.dropdown.empty' })
          : 'No categories available'}
      </span>
    );
  };

  const [triggerWidth, setTriggerWidth] = useState<string>('auto');
  const buttonRef = useCallback((node: HTMLButtonElement | null) => {
    if (node !== null) {
      const newWidth = node.getBoundingClientRect().width;
      setTriggerWidth(`${newWidth.toString()}px`);
    }
  }, []);

  return (
    <Select
      className={cx(styles.select)}
      selectedKey={selectedCategoryId}
      onOpenChange={handleOpenChange}
      onSelectionChange={handleSelectionChange}
      data-testid="cash-flow-category-selector"
      isDisabled={!hasCategories}
    >
      <Label className={cx(styles.title)} data-testid="title">
        {formatMessage({ id: 'transactions.sidebar.category' })}
      </Label>
      {isLoading ? (
        <SkeletonLoader.Line width="100%" height="40px" borderRadius="4px" />
      ) : (
        <Button
          ref={buttonRef}
          className={cx('body-1', 'overlay', styles.trigger)}
          data-testid="trigger"
        >
          {displaySelectedCategory()}
          <ChevronBottomOutlined aria-hidden="true" className={cx(styles['arrow-icon'])} />
        </Button>
      )}
      {hasCategories ? (
        <Popover style={{ width: triggerWidth }}>
          <div data-testid="cash-flow-category-popover" className={cx(styles.popover)}>
            <ListBox items={categoriesData} className={cx(styles['list-box'])}>
              {inOutFlow => {
                if (!inOutFlow.categories.length) return null;
                return (
                  <Section id={inOutFlow.name} className={cx(styles.section)} data-testid="section">
                    <Header className={cx(styles['section-title'])} data-testid="section-title">
                      {inOutFlow.name}
                    </Header>
                    <Collection items={inOutFlow.categories}>
                      {item => {
                        return <ParentCategory category={item} />;
                      }}
                    </Collection>
                  </Section>
                );
              }}
            </ListBox>
          </div>
        </Popover>
      ) : null}
    </Select>
  );
}
