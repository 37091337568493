import type { ReactElement } from 'react';
import { Badge, BadgeStatus } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import {
  Selector,
  type SelectorProps,
} from 'qonto/react/components/account-receivable/summary/selector/selector';
import { Company, Calculator, Invoice, Pole } from 'qonto/react/assets/icons/account-receivable';
import { renderLegacyHTMLTranslatedContent } from 'qonto/react/components/translation-helper/legacy';
import styles from './styles.strict-module.css';

const icons = {
  pole: Pole,
  company: Company,
  invoice: Invoice,
  calculator: Calculator,
};

export type SubStepStatus = 'toDo' | 'prefilled' | 'confirmed' | 'tryForFree' | 'upgrade';
export type StepSelectorProps = Omit<SelectorProps, 'icon'> & {
  status: SubStepStatus;
  icon: keyof typeof icons;
};

interface StatusBadgeProps {
  status: SubStepStatus;
  className?: string;
}

function StatusBadge({ status, className }: StatusBadgeProps): ReactElement | null {
  const { formatMessage } = useIntl();

  switch (status) {
    case 'confirmed':
      return (
        <BadgeStatus
          level="validated"
          className={className}
          text={formatMessage({
            id: 'receivable-invoices.onboarding.status-badge.completed',
          })}
        />
      );
    case 'prefilled':
      return (
        <BadgeStatus
          level="waiting"
          className={className}
          text={formatMessage({
            id: 'receivable-invoices.onboarding.status-badge.to-review',
          })}
        />
      );
    case 'tryForFree':
      return (
        <Badge
          type="tryForFree"
          className={className}
          text={formatMessage({
            id: 'promotion-system.badge.trial.label',
          })}
        />
      );
    case 'upgrade':
      return (
        <Badge
          type="upgrade"
          className={className}
          text={formatMessage({
            id: 'promotion-system.badge.upgrade.label',
          })}
        />
      );
    default:
      return null;
  }
}

export function StepSelector({
  title,
  subtitle,
  status,
  icon,
  ...props
}: StepSelectorProps): ReactElement {
  const computedTitle = (
    <span className={styles.title}>
      {title}
      <StatusBadge status={status} className={styles.badge} />
    </span>
  );

  const IconComponent = icons[icon];
  const computedIcon: ReactElement = <IconComponent />;

  const computedSubtitle: ReactElement | undefined = subtitle
    ? renderLegacyHTMLTranslatedContent(subtitle as string)
    : undefined;

  return (
    <Selector {...props} title={computedTitle} subtitle={computedSubtitle} icon={computedIcon} />
  );
}
