import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useRef, useEffect } from 'react';
import type { DOMAttributes, PropsWithChildren } from 'react';
import { useOverlayPosition, OverlayContainer } from 'react-aria';
import type { AriaPositionProps } from 'react-aria';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { Button, Badge, LottiePlayer, type BaseButtonProps } from '@repo/design-system-kit';
import ENV from 'qonto/config/environment';
import { ArrowRightOutlined, CrossOutlined } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

const PLACEMENT = {
  lowerLeft: 'lower-left',
  upperLeft: 'upper-left',
  lowerRight: 'lower-right',
  upperRight: 'upper-right',
  top: 'top',
  bottom: 'bottom',
} as const;

const placementProps: Record<Placement, SinglePlacementProps> = {
  [PLACEMENT.lowerLeft]: {
    positionProps: {
      placement: 'left bottom',
      crossOffset: 78,
    },
    classNames: [styles['arrow--lower'], styles['arrow--left']],
  },
  [PLACEMENT.upperLeft]: {
    positionProps: {
      placement: 'left top',
      crossOffset: -52,
    },
    classNames: [styles['arrow--upper'], styles['arrow--left']],
  },
  [PLACEMENT.lowerRight]: {
    positionProps: {
      placement: 'right bottom',
      crossOffset: 78,
    },
    classNames: [styles['arrow--lower'], styles['arrow--right']],
  },
  [PLACEMENT.upperRight]: {
    positionProps: {
      placement: 'right top',
      crossOffset: -52,
    },
    classNames: [styles['arrow--upper'], styles['arrow--right']],
  },
  [PLACEMENT.top]: {
    positionProps: {
      placement: 'top',
      crossOffset: 0,
    },
    classNames: [styles['arrow--horizontally-centered'], styles['arrow--top']],
  },
  [PLACEMENT.bottom]: {
    positionProps: {
      placement: 'bottom',
      crossOffset: 0,
    },
    classNames: [styles['arrow--horizontally-centered'], styles['arrow--bottom']],
  },
};

type PlacementsMap = typeof PLACEMENT;
type Placement = PlacementsMap[keyof PlacementsMap];

interface SinglePlacementProps {
  positionProps: Pick<AriaPositionProps, 'placement' | 'crossOffset'>;
  classNames: (string | undefined)[];
}

type LottieSrc = string;
type ImageSrc = string;

interface TooltipIllustrationProps {
  lottieSrc?: LottieSrc;
  imageSrc?: ImageSrc;
}

function TooltipIllustration({
  lottieSrc,
  imageSrc,
}: TooltipIllustrationProps): JSX.Element | null {
  if (lottieSrc) {
    return (
      <LottiePlayer
        path={lottieSrc}
        className={cx(styles.illustration)}
        data-test-instructional-tooltip-illustration
      />
    );
  }

  if (imageSrc) {
    return (
      <img
        alt=""
        src={imageSrc}
        className={cx(styles.illustration)}
        data-test-instructional-tooltip-illustration
      />
    );
  }

  return null;
}

function TooltipContainer({ children }: PropsWithChildren): JSX.Element {
  if (ENV.environment !== 'test') {
    return <OverlayContainer>{children}</OverlayContainer>;
  }

  // In test environment, we need another container as react portal for overlays because it doesn't work in test
  const portalContainer = document.getElementById('ember-testing') ?? undefined;

  return <OverlayContainer portalContainer={portalContainer}>{children}</OverlayContainer>;
}

interface Cta {
  onPress: () => void;
  isExternal: boolean;
  button: BaseButtonProps;
  text: string;
}

interface TooltipBaseProps {
  placement: Placement;
  title: string;
  description: string;
  faqLinkText: string;
  faqLinkUrl: string;
  faqLinkEventName: string;
  faqLinkEventOrigin: string;
  isDismissed: boolean;
  isNew: boolean;
  onClose: () => void;
  popoverClassName?: string;
  screenReaderOnlyDesc?: string;
  crossOffset?: number;
  cta?: Cta;
}

interface LottieProps extends TooltipBaseProps {
  lottieSrc: LottieSrc;
  imageSrc?: never;
}

interface ImageProps extends TooltipBaseProps {
  lottieSrc?: never;
  imageSrc: ImageSrc;
}

type TooltipProps = LottieProps | ImageProps;

interface PositionAria {
  overlayProps: DOMAttributes<HTMLDivElement>;
  updatePosition: () => void;
}

export function InstructionalTooltip({
  placement = PLACEMENT.lowerLeft,
  title,
  description,
  faqLinkText,
  faqLinkUrl,
  faqLinkEventName,
  faqLinkEventOrigin,
  isDismissed,
  isNew = true,
  lottieSrc,
  imageSrc,
  onClose,
  popoverClassName,
  screenReaderOnlyDesc,
  crossOffset,
  cta,
}: TooltipProps): JSX.Element {
  const targetRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const previousFocusRef = useRef<EventTarget | null>(null);

  const { formatMessage } = useIntl();

  const segment = useEmberService('segment');

  const positionProps = {
    ...placementProps[placement].positionProps,
    crossOffset: crossOffset ?? placementProps[placement].positionProps.crossOffset,
  };

  const { overlayProps, updatePosition } = useOverlayPosition({
    targetRef,
    overlayRef: popoverRef,
    isOpen: true,
    shouldFlip: false,
    // This is necessary to allow tooltip to sit outside of the screen
    containerPadding: -300,
    offset: 12,
    ...positionProps,
  }) as PositionAria;

  useEffect(() => {
    previousFocusRef.current = document.activeElement;
    popoverRef.current?.querySelector('button')?.focus();

    return () => {
      if (previousFocusRef.current instanceof HTMLElement) {
        previousFocusRef.current.focus();
      }
    };
  }, []);

  useEffect(() => {
    const onScroll = (): void => {
      updatePosition();
    };

    window.addEventListener('scroll', onScroll, { capture: true });

    return () => {
      window.removeEventListener('scroll', onScroll, { capture: true });
    };
  }, [updatePosition]);

  const handlePress = (): void => {
    onClose();
  };

  const handleEventOnClick = (): void => {
    if (faqLinkEventName) {
      segment.track(faqLinkEventName, { origin: faqLinkEventOrigin });
    }
  };

  return (
    <>
      <div ref={targetRef} className={cx(styles['trigger--empty'])} />
      {!isDismissed && (
        <TooltipContainer>
          <div
            ref={popoverRef}
            {...overlayProps}
            className={cx([styles.popover, popoverClassName])}
            role="alert"
            data-test-instructional-tooltip-content
          >
            <div
              className={cx([styles.arrow, ...placementProps[placement].classNames])}
              data-test-instructional-tooltip-arrow
            >
              <svg className={cx(styles['arrow-icon'])} width="12" height="32" viewBox="0 0 12 32">
                <path d="M8.19869 14.6584C9.30426 15.2111 9.30426 16.7889 8.19868 17.3416L-1.32918 22.1056C-2.32653 22.6042 -3.5 21.879 -3.5 20.7639L-3.5 16L-3.5 11.2361C-3.5 10.121 -2.32653 9.39575 -1.32918 9.89443L8.19869 14.6584Z" />
              </svg>
            </div>

            <div className={cx(styles['illustration-wrapper'])}>
              <TooltipIllustration lottieSrc={lottieSrc} imageSrc={imageSrc} />
            </div>

            <div className={cx(styles.text)}>
              {isNew ? (
                <div className="mb-8">
                  <Badge
                    type="newFeature"
                    text={formatMessage({ id: 'promotion-system.badge.new.label' })}
                  />
                </div>
              ) : null}
              <div className="mb-4 body-1" data-test-instructional-tooltip-title>
                {title}
              </div>
              <div
                className={cx(['body-2', styles.description])}
                data-test-instructional-tooltip-description
              >
                {description}{' '}
                {Boolean(faqLinkText && faqLinkUrl) && (
                  <a
                    className={cx(['body-link', 'faq-link'])}
                    href={faqLinkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-test-instructional-tooltip-faq
                    onClick={handleEventOnClick}
                  >
                    {faqLinkText}
                  </a>
                )}
              </div>
              {Boolean(screenReaderOnlyDesc) && (
                <div className="sr-only" data-test-instructional-tooltip-sr-only>
                  {screenReaderOnlyDesc}
                </div>
              )}
              {cta ? (
                <Button
                  variant={cta.button.variant}
                  size={cta.button.size}
                  className={cx(styles.cta)}
                  onPress={cta.onPress}
                  data-test-instructional-tooltip-cta
                >
                  {cta.text}
                  {cta.isExternal ? (
                    <div
                      className={cx(styles['cta-icon'])}
                      data-test-instructional-tooltip-cta-external
                    >
                      <ArrowRightOutlined />
                    </div>
                  ) : null}
                </Button>
              ) : null}
            </div>

            <Button
              variant="tertiary"
              iconOnly
              aria-label={formatMessage({ id: 'a11y.buttons.close-aria-label' })}
              className={cx(styles['close-button'])}
              onPress={handlePress}
              data-test-instructional-tooltip-close
            >
              <CrossOutlined />
            </Button>
          </div>
        </TooltipContainer>
      )}
    </>
  );
}
