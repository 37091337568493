import { useIntl } from 'react-intl';
import { SubscriptionSuccess } from '@repo/domain-kit';
import type { ReactElement } from 'react';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';

export interface SuccessItalyCSProps {
  onClick: () => void;
}

export function SuccessItalyCS({ onClick }: SuccessItalyCSProps): ReactElement {
  const { formatMessage } = useIntl();

  return (
    <SubscriptionSuccess
      title={formatMessage({ id: 'subscription.change.success.italy-restrictions.title' })}
      subtitle={formatMessage({ id: 'subscription.change.success.italy-restrictions.subtitle' })}
      ctaText={formatMessage({ id: 'btn.close' })}
      onClick={onClick}
      stretch={false}
      asset={
        <StaticThemedAsset
          alt=""
          assetPath="/illustrations/concierge/concierge.svg"
          className={styles.image}
          data-test-subscription-success-image
        />
      }
    />
  );
}
