import type { ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';

export function VirtualCard(): ReactNode {
  return (
    <div className={`body-1 ${cx(styles['cell-content'])}`} data-test-request-cell-virtual-card>
      <StaticThemedAsset
        assetPath="/illustrations/cards/card-virtual.svg"
        className={styles['card-icon']}
        data-test-request-cell-virtual-card-icon
      />
      <FormattedMessage id="cards.fullname.virtual" />
    </div>
  );
}
