import { useState, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface ImageGalleryProps {
  images: { url: string; alt?: string }[];
  integrationName?: string;
}

export function ImageGallery({ images, integrationName, ...props }: ImageGalleryProps): ReactNode {
  const { formatMessage } = useIntl();

  const imgs = images.slice(0, 4);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const activeImage = imgs[activeImageIndex];

  return (
    <div {...props}>
      <div className={cx('mb-32', styles['active-image-container'])}>
        <img
          alt={activeImage?.alt ?? integrationName}
          src={activeImage?.url}
          className={cx(styles['active-image'])}
          data-test-active-image
        />
      </div>

      <div className={cx(styles['image-button-container'])}>
        {imgs.map((image, index) => (
          <button
            key={image.url}
            className={cx(styles['image-button'], activeImageIndex === index && 'active')}
            type="button"
            aria-label={formatMessage({
              id: 'qonto-hub.connect.details.description.image-button.aria-label',
            })}
            data-test-image-button
            onClick={() => {
              setActiveImageIndex(index);
            }}
          >
            <img alt={image.alt ?? integrationName} src={image.url} data-test-image={index} />
          </button>
        ))}
      </div>
    </div>
  );
}
