import { type ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

export interface DataWithIconProps {
  title?: string;
  subtitle?: string | null;
  icon?: ReactNode;
  type?: 'info' | 'error';
}

export function DataWithIconCell({ icon, title, subtitle, type }: DataWithIconProps): ReactNode {
  return (
    <div
      data-testid="data-with-icon-cell"
      data-test-type={type ?? ''}
      className={cx(styles.container)}
    >
      {icon ? (
        <div
          data-testid="cell-icon"
          className={cx(styles.icon, type && !subtitle && styles[type])}
          aria-hidden="true"
        >
          {icon}
        </div>
      ) : null}
      <div className={styles['title-group']}>
        <p
          data-testid="cell-title"
          className={cx(styles.title, type && !subtitle && styles[type], 'body-2')}
        >
          {title}
        </p>
        {subtitle ? (
          <p data-testid="cell-subtitle" className={cx(styles.subtitle, type && styles[type])}>
            {subtitle}
          </p>
        ) : null}
      </div>
    </div>
  );
}
