import { type ReactElement } from 'react';
import cx from 'clsx';
import { ActionSlot } from 'qonto/react/components/table-v2/action-slot';
import styles from './styles.strict-module.css';

interface AttachmentItemBaseProps {
  icon: ReactElement;
  fileName: ReactElement;
  subtitle?: ReactElement;
  actions?: ReactElement;
}

export function AttachmentItemBase({
  fileName,
  icon,
  subtitle,
  actions,
  ...props
}: AttachmentItemBaseProps): ReactElement {
  return (
    <div className={cx('overlay', styles.container)} data-testid="item" {...props}>
      {icon}
      {subtitle ? (
        <div className={styles['content-wrapper']}>
          {fileName}
          {subtitle}
        </div>
      ) : (
        fileName
      )}

      <ActionSlot size="28" className={styles.action}>
        {actions}
      </ActionSlot>
    </div>
  );
}
