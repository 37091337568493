import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import type { Transaction } from 'qonto/react/graphql';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { OperationMethod } from 'qonto/react/graphql';

interface PaymentMethodCellProps {
  transaction: Transaction;
}

export function PaymentMethodCell({
  transaction: { operationMethod },
}: PaymentMethodCellProps): ReactNode {
  const labelForOperationMethod = {
    [OperationMethod.Biller]: <FormattedMessage id="transactions.operation-types.biller" />,
    [OperationMethod.Card]: <FormattedMessage id="transactions.operation-types.card" />,
    [OperationMethod.Cheque]: <FormattedMessage id="transactions.operation-types.cheque" />,
    [OperationMethod.DirectDebit]: (
      <FormattedMessage id="transactions.operation-types.direct-debit" />
    ),
    [OperationMethod.DirectDebitHold]: (
      <FormattedMessage id="transactions.operation-types.direct-debit-hold" />
    ),
    [OperationMethod.PagopaPayment]: (
      <FormattedMessage id="transactions.operation-types.pagopa-payment" />
    ),
    [OperationMethod.Tax]: <FormattedMessage id="transactions.operation-types.tax" />,
    [OperationMethod.Transfer]: <FormattedMessage id="transactions.operation-types.transfer" />,
    [OperationMethod.PayLater]: <FormattedMessage id="transactions.operation-types.pay-later" />,
    [OperationMethod.Other]: <FormattedMessage id="transactions.operation-types.unknown" />,
  };

  return <BaseCell>{labelForOperationMethod[operationMethod]}</BaseCell>;
}
