import { type FC, type ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { Badge } from '@repo/design-system-kit';
import { SddOneOff, SddRecurring, SddRecurringInvoice } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

type CardType = 'one-off' | 'recurring' | 'recurring-invoice';

interface TypeCardProps {
  type: CardType;
  showUnlockBadge?: boolean;
  onClick: (type: CardType) => void;
}

const getTypeIcon = (type: CardType): FC<{ className?: string }> => {
  switch (type) {
    case 'one-off':
      return SddOneOff;
    case 'recurring':
      return SddRecurring;
    case 'recurring-invoice':
      return SddRecurringInvoice;
  }
};

export function TypeCard({ type, showUnlockBadge, onClick }: TypeCardProps): ReactNode {
  const { formatMessage } = useIntl();
  const TypeIcon = getTypeIcon(type);

  return (
    <button
      type="button"
      className={cx(styles['type-card'])}
      data-test-type-card={type}
      onClick={() => {
        onClick(type);
      }}
    >
      <TypeIcon className={cx(styles['type-icon'])} data-test-type-icon={type} />

      {showUnlockBadge ? (
        <Badge
          type="upgrade"
          text={formatMessage({ id: 'upsell.add-ons.badge' })}
          className="mb-8"
          data-test-type-card-unlock-badge
        />
      ) : null}

      <header className="title-3 mb-8" data-test-type-card-title>
        <FormattedMessage id={`sdd-collections.creation-flow.type-selection.${type}.title`} />
      </header>

      <span className="body-2" data-test-type-card-subtitle>
        <FormattedMessage id={`sdd-collections.creation-flow.type-selection.${type}.description`} />
      </span>
    </button>
  );
}
