import { Button, LottiePlayer, type BaseButtonProps } from '@repo/design-system-kit';
import cx from 'clsx';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import styles from './styles.strict-module.css';

enum Type {
  Default = 'default',
  Standalone = 'standalone',
}

interface Button {
  text: string;
  variant?: BaseButtonProps['variant'];
}

interface StatePropsBase extends ComponentPropsWithoutRef<'section'> {
  type?: Type;
  lottieSrc: string;
  title: string;
  legend: string;
}

interface StatePropsWithButton extends StatePropsBase {
  button: Button;
  onRetry: () => void;
}

interface StatePropsWithoutButton extends StatePropsBase {
  button?: never;
  onRetry?: never;
}

type StateProps = StatePropsWithButton | StatePropsWithoutButton;

export function State({
  type = Type.Default,
  lottieSrc,
  title,
  legend,
  button,
  onRetry,
  className,
  ...props
}: StateProps): ReactNode {
  const isStandalone = type === Type.Standalone;
  const hasButton = button && onRetry;

  return (
    <section className={cx(className, styles.wrapper)} {...props} data-test-state={type}>
      <LottiePlayer
        path={lottieSrc}
        className={cx(styles.lottie, isStandalone && styles.lg, 'mb-32')}
        data-test-state-lottie={lottieSrc}
      />

      <div className={cx(isStandalone ? 'title-1 mb-16' : 'title-4 mb-8')} data-test-state-title>
        {title}
      </div>

      <div
        className={cx(isStandalone ? 'body-1' : 'body-2', hasButton && 'mb-32')}
        data-test-state-legend
      >
        {legend}
      </div>

      {hasButton ? (
        <Button
          onPress={onRetry}
          size={isStandalone ? 'medium' : 'small'}
          variant={button.variant ?? 'primary'}
          data-test-state-button={button.variant ?? 'primary'}
        >
          {button.text}
        </Button>
      ) : null}
    </section>
  );
}
