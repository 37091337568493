import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import styles from './styles.strict-module.css';

export function Header(): ReactNode {
  return (
    <>
      <th scope="col" className={cx(styles['header-cell'], styles.empty)} aria-hidden="true" />
      <th scope="col" className={cx(styles['header-cell'])} data-test-table-header-statement>
        <FormattedMessage id="statements.list.columns.statement" />
      </th>
      <th scope="col" className={cx(styles['header-cell'])} data-test-table-header-date>
        <FormattedMessage id="statements.list.columns.date" />
      </th>
      <th scope="col" className={cx(styles['header-cell'])} data-test-table-header-account>
        <FormattedMessage id="statements.list.columns.account" />
      </th>
      <th scope="col" className={cx(styles['header-cell'])} data-test-table-header-limit />
      <th scope="col" className={cx(styles['header-cell'], styles.empty)} aria-hidden="true" />
    </>
  );
}
