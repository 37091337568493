import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { External } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface ApplyCtaProps {
  link: string;
  partnerName: string;
  financingType: string;
  canRequestFinancing: boolean;
}

export function ApplyCta({
  link,
  partnerName,
  financingType,
  canRequestFinancing,
}: ApplyCtaProps): ReactNode {
  const segment = useEmberService('segment');

  const handleClick = (): void => {
    segment.track('financing_detail_cta', {
      partner: partnerName,
      type: financingType,
    });
  };

  return (
    <div>
      {canRequestFinancing ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={cx(styles.button, 'btn', 'btn--primary')}
          onClick={handleClick}
          data-test-apply-link
        >
          <FormattedMessage id="financing.details.apply-cta" />
          <External className={cx(styles['link-icon'])} />
        </a>
      ) : (
        <button
          type="button"
          className={cx(styles.button, 'btn', 'btn--primary')}
          disabled
          data-test-apply-button
        >
          <FormattedMessage id="financing.details.apply-cta" />
          <External className={cx(styles['link-icon'])} />
        </button>
      )}
    </div>
  );
}
