import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { Button, type BaseButtonProps } from '@repo/design-system-kit';
import { AddOnDetail } from '@repo/domain-kit';
import type { ReactElement } from 'react';
import styles from './styles.strict-module.css';

export interface AddonCardsProps {
  addons: Addon[];
  recurrence: SubscriptionRecurrences;
  onConfirm: (addon: Addon) => void;
  onToggleChange: (addonCode: string) => void;
  loadingAddonCode: string;
}
interface Trait {
  title: string;
  name: 'active' | 'free_trial';
}

interface Subscription {
  hasInitialTrial: boolean;
}

enum SubscriptionRecurrences {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
}

interface Price {
  value: string;
  currency: string;
}

interface Addon {
  name: string;
  code: string;
  type: string;
  brand_name: string;
  description: string;
  description_has_decoration: boolean;
  sub_description: string | null;
  monthly_price: {
    prefix: string | null;
    per_month_amount: Price;
    per_year_amount: Price;
    period: string;
    billing_recurrence: string;
    annual_benefit: string;
  };
  annual_price: {
    prefix: string | null;
    per_month_amount: Price;
    per_year_amount: Price;
    period: string;
    billing_recurrence: string;
    annual_benefit: string;
  };
  benefit_groups: [
    {
      title: string;
      badge: string | null;
      benefits: [
        {
          description: string;
          description_has_decoration: boolean;
          is_included: boolean;
        },
        {
          description: string;
          description_has_decoration: boolean;
          is_included: boolean;
        },
      ];
    },
  ];
}

export function AddonCards({
  addons,
  recurrence,
  onToggleChange,
  onConfirm,
  loadingAddonCode,
}: AddonCardsProps): ReactElement {
  const subscriptionManager = useEmberService('subscription-manager');
  const { formatMessage, formatNumber } = useIntl();

  const addonsInfo = addons.map(addon => {
    const {
      brand_name: brandName,
      code,
      sub_description: subDescription,
      benefit_groups: benefitGroups,
      monthly_price: monthlyPrice,
      annual_price: annualPrice,
    } = addon;
    const benefitsInfo = benefitGroups[0];
    const priceObjectByRecurrence =
      recurrence === SubscriptionRecurrences.MONTHLY ? monthlyPrice : annualPrice;

    const priceMonthly = formatNumber(Number(monthlyPrice.per_month_amount.value), {
      currency: monthlyPrice.per_month_amount.currency,
      style: 'currency',
      currencyDisplay: 'symbol',
      notation: 'compact',
    });

    const priceObject = priceObjectByRecurrence.per_month_amount;
    const price = formatNumber(Number(priceObject.value), {
      currency: priceObject.currency,
      style: 'currency',
      currencyDisplay: 'symbol',
    });

    const ctaProps: { title: string; variant: BaseButtonProps['variant'] } = {
      title: formatMessage({ id: 'subscription.change.addons.summary.choose-addon' }),
      variant: 'primary',
    };

    if (subscriptionManager.getProduct(code)) {
      if (subscriptionManager.hasProductAndRecurrence(code, recurrence)) {
        ctaProps.title = formatMessage({
          id: 'subscription.change.addons.summary.remove-addon',
        });
        ctaProps.variant = 'secondary';
      } else {
        ctaProps.title =
          recurrence === SubscriptionRecurrences.MONTHLY
            ? formatMessage({ id: 'subscription.change.addons.summary.monthly' })
            : formatMessage({ id: 'subscription.change.addons.summary.annual' });
      }
    }

    const traits: Trait[] = [];
    if (subscriptionManager.hasProductAndRecurrence(code, recurrence)) {
      traits.push({
        title: formatMessage({ id: 'subscription.change.addons.badges.added' }),
        name: 'active',
      });
    }

    if (
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- changing to nullish coalescing breaks the logic
      (subscriptionManager.currentSubscription as Subscription | undefined)?.hasInitialTrial ||
      subscriptionManager.hasAvailableTrialProduct(code, recurrence)
    ) {
      traits.push({
        title: formatMessage({ id: 'subscription.change.addons.badges.free-trial' }),
        name: 'free_trial',
      });
    }

    const prefix = priceObjectByRecurrence.prefix;
    let annualSwitch = null;
    const isAddedAnnually = Boolean(
      subscriptionManager.hasProductAndRecurrence(code, SubscriptionRecurrences.ANNUAL)
    );
    if (recurrence === SubscriptionRecurrences.MONTHLY && isAddedAnnually) {
      annualSwitch = (
        <span
          className={cx(styles.recurrence, 'caption')}
          data-test-addon-detail-card-billing-recurrence
        >
          {annualPrice.annual_benefit}
        </span>
      );
    } else if (recurrence === SubscriptionRecurrences.MONTHLY && !isAddedAnnually) {
      annualSwitch = (
        <Button
          className="caption"
          onPress={() => {
            onToggleChange(code);
          }}
          variant="tertiary"
          data-test-addon-detail-card-billing-recurrence-button
        >
          {monthlyPrice.annual_benefit}
        </Button>
      );
    }

    return {
      name: brandName,
      code,
      recurrence,
      price,
      priceMonthly,
      pricePrefix: prefix ?? undefined,
      annualSwitch,
      pricePeriod: priceObjectByRecurrence.period,
      benefitForAnnualPrice: annualPrice.annual_benefit,
      features: benefitsInfo.benefits.map(benefit => benefit.description),
      featuresTitle: benefitsInfo.title,
      traits,
      subtitle: subDescription ?? undefined,
      cta: (
        <Button
          variant={ctaProps.variant}
          onPress={() => {
            onConfirm(addon);
          }}
          isLoading={loadingAddonCode === code}
          stretch
          data-test-cta
        >
          {ctaProps.title}
        </Button>
      ),
    };
  });

  return (
    <>
      {addonsInfo.map(addon => (
        <AddOnDetail
          name={addon.name}
          annualSwitch={addon.annualSwitch}
          key={addon.code}
          recurrence={recurrence}
          subtitle={addon.subtitle}
          pricePrefix={addon.pricePrefix}
          price={addon.price}
          priceMonthly={addon.priceMonthly}
          billingRecurrence={addon.benefitForAnnualPrice}
          pricePeriod={addon.pricePeriod}
          featuresTitle={addon.featuresTitle}
          features={addon.features}
          traits={addon.traits}
          mainButton={addon.cta}
        />
      ))}
    </>
  );
}
