import React, { useEffect, useId, useRef, type ComponentPropsWithRef, type ReactNode } from 'react';
import { type Uppy } from '@uppy/core';
import cx from 'clsx';
import { useEventCallback } from 'usehooks-ts';
import type { RestrictionError } from '@uppy/core/lib/Restricter';
import { PlusRoundedOutlined } from 'qonto/react/assets/icons';
import type { Body, Meta } from 'qonto/react/hooks/use-attachments-uploader';
import { PopoverButton } from '../popover';
import styles from './styles.strict-module.css';

const DEFAULT_EXTENSIONS = 'pdf,jpg,jpeg,png';

interface AttachmentUploaderProps extends ComponentPropsWithRef<'div'> {
  uppy: Uppy<Meta, Body>;
  isDisabled?: boolean;
  buttonLabel: string;
  className?: string;
}

export function AttachmentUploader({
  uppy,
  isDisabled = false,
  buttonLabel,
  ...props
}: AttachmentUploaderProps): ReactNode {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const inputIdSuffix = useId();
  const inputId = `attachment-uploader-${inputIdSuffix}`;

  const handleFileChange = useEventCallback((event: Event) => {
    const target = event.target as HTMLInputElement;
    const files = Array.from(target.files ?? []);

    files.forEach(file => {
      try {
        uppy.addFile({
          source: 'attachment input',
          name: file.name,
          type: file.type,
          data: file,
        });
      } catch (err) {
        const error = err as RestrictionError<Meta, Body>;
        if (error.isRestriction) {
          // TODO: handle upload restriction errors
        } else {
          // TODO: handle other upload errors
        }
      }
    });
  });

  useEffect(() => {
    if (fileInputRef.current) {
      const fileInput = fileInputRef.current;
      fileInput.addEventListener('change', handleFileChange);

      return () => {
        fileInput.removeEventListener('change', handleFileChange);
      };
    }
  }, [handleFileChange]);

  return (
    <div data-testid="attachment-uploader" {...props}>
      <input
        ref={fileInputRef}
        accept={DEFAULT_EXTENSIONS}
        className={cx(styles.hidden)}
        id={inputId}
        type="file"
        disabled={isDisabled}
        multiple
      />
      <label htmlFor={inputId}>
        <PopoverButton
          onPress={() => {
            fileInputRef.current?.click();
          }}
          slots={{
            icon: <PlusRoundedOutlined data-testid="default-icon" />,
            text: buttonLabel,
          }}
          data-testid="upload-file-button"
          aria-label={buttonLabel}
        />
      </label>
    </div>
  );
}
