import { useState, type ReactNode } from 'react';
import { useIntl, type IntlShape } from 'react-intl';
import { useEventCallback } from 'usehooks-ts';
import type { UploadResult, Uppy } from '@uppy/core';
import { Spinner } from '@repo/design-system-kit';
import useUppyState from '@uppy/react/lib/useUppyState';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import { type Attachment } from 'qonto/react/graphql';
import { AttachmentOutlined } from 'qonto/react/assets/icons/attachment-outlined';
import type { DataWithIconProps } from 'qonto/react/components/table-v2/cells/data-with-icon-cell/data-with-icon-cell';
import { AttachmentWarningOutlined } from 'qonto/react/assets/icons/attachment-warning-outlined';
import { CheckmarkOutlined } from 'qonto/react/assets/icons';
import { AttachmentMissingOutlined } from 'qonto/react/assets/icons/attachment-missing-outlined';
import {
  useAttachmentsUploader,
  type Body,
  type Meta,
} from 'qonto/react/hooks/use-attachments-uploader';
import { apiBaseURL } from 'qonto/constants/hosts';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { useFetchApi } from 'qonto/react/hooks/use-fetch-api';
import { useRefetchTransaction } from 'qonto/react/hooks/mutations/use-refetch-transaction';
import { AttachmentCellPopover } from './popover/attachment-cell-popover';

interface NoAttachmentProps {
  required: boolean;
  lost: boolean;
  uppy: Uppy<Meta, Body>;
  isUploading: boolean;
}

function NoAttachment({ isUploading, required, lost, uppy }: NoAttachmentProps): ReactNode {
  const intl = useIntl();
  const { type, title, icon } = getNoAttachmentStatus(lost, required, intl, isUploading);

  return (
    <BaseCell
      popoverSlot={
        <AttachmentCellPopover
          isUploading={isUploading}
          attachments={[]}
          uppy={uppy}
          title={title}
          icon={icon}
        />
      }
    >
      <DataWithIconCell title={title} icon={icon} type={type} />
    </BaseCell>
  );
}

interface SingleAttachmentProps {
  lost: boolean;
  attachments: Attachment[];
  uppy: Uppy<Meta, Body>;
  isUploading: boolean;
}

function SingleAttachment({
  isUploading,
  lost,
  attachments,
  uppy,
}: SingleAttachmentProps): ReactNode {
  const { formatMessage } = useIntl();
  if (!attachments[0]?.file) {
    return null;
  }

  const { file } = attachments[0];
  const name = file.name;
  const fileName = name.length > 20 ? `⋅⋅ ${name.slice(-20)}` : name;
  const getCellMeta = (): {
    title: string;
    subtitle?: string;
    type?: 'error' | undefined;
    icon: ReactNode;
  } => {
    if (isUploading) {
      return {
        title: formatMessage({
          id: 'transactions.table.attachments.status.title.uploading',
        }),
        icon: <Spinner color="primary-a" />,
      };
    }

    if (lost) {
      return {
        title: fileName,
        subtitle: formatMessage({
          id: 'transactions.table.attachments.status.title.lost',
        }),
        type: 'error',
        icon: <AttachmentWarningOutlined />,
      };
    }

    return {
      title: fileName,
      icon: <AttachmentOutlined />,
    };
  };

  return (
    <BaseCell
      popoverSlot={
        <AttachmentCellPopover isUploading={isUploading} uppy={uppy} attachments={attachments} />
      }
    >
      <DataWithIconCell {...getCellMeta()} />
    </BaseCell>
  );
}

interface AttachmentCellProps {
  attachments: Attachment[];
  required?: boolean;
  lost?: boolean;
}

export function AttachmentCell({
  attachments,
  required = false,
  lost = false,
}: AttachmentCellProps): ReactNode {
  const { transactionId } = cellContextManager.useCellContext();
  const { mutate: refetchTransaction, isPending } = useRefetchTransaction(transactionId);
  const fetchApi = useFetchApi();
  const { formatMessage } = useIntl();
  const [isLinkingAttachments, setIsLinkingAttachments] = useState(false);

  const onComplete = useEventCallback((result: UploadResult<Meta, Body>) => {
    const { successful } = result;
    const linkSuccessfulUploads = async (): Promise<void> => {
      const attachmentIds = successful?.map(({ response }) => {
        return response?.body?.attachment.id;
      });

      if (attachmentIds?.length === 0) {
        return;
      }

      await fetchApi(`v7/transactions/${transactionId}/link_attachments`, {
        method: 'PATCH',
        body: JSON.stringify({
          transaction: { attachment_ids: attachmentIds },
        }),
      });
    };

    if (successful?.length) {
      setIsLinkingAttachments(true);
      linkSuccessfulUploads()
        .then(() => {
          setIsLinkingAttachments(false);
          refetchTransaction(undefined, {
            onSuccess: () => {
              uppy.clear();
            },
          });
        })
        .catch(() => {
          /* TODO: handle linking errors */
        });
    }
  });

  const uppy = useAttachmentsUploader({
    onFileUploadSuccess: (): void => {
      // TODO: increment uploaded files count for popover header
    },
    onFileUploadError: (): void => {
      // TODO: implement file upload error handling
    },
    endpoint: `${apiBaseURL}/v3/attachments`,
    onComplete,
  });
  const currentUploads = useUppyState(uppy, state => state.currentUploads);
  const isUploading = Object.values(currentUploads).length > 0 || isLinkingAttachments || isPending;

  if (!attachments.length) {
    return <NoAttachment isUploading={isUploading} uppy={uppy} required={required} lost={lost} />;
  }

  if (attachments.length === 1) {
    return (
      <SingleAttachment
        isUploading={isUploading}
        uppy={uppy}
        lost={lost}
        attachments={attachments}
      />
    );
  }

  const getCellMeta = (): {
    title: string;
    subtitle?: string;
    type?: 'error' | undefined;
    icon: ReactNode;
  } => {
    if (isUploading) {
      return {
        title: formatMessage({
          id: 'transactions.table.attachments.status.title.uploading',
        }),
        icon: <Spinner color="primary-a" />,
      };
    }

    const { pending, corrupted } = getMultiProbativeStatus(attachments);
    let subtitle: string | undefined;
    if (corrupted) {
      subtitle = `${corrupted.toString()} docs not valid`;
    } else if (pending) {
      subtitle = `${pending.toString()} pending for verification`;
    }

    return {
      title: attachments.length.toString(),
      subtitle,
      icon: <AttachmentOutlined />,
      type: corrupted ? 'error' : undefined,
    };
  };

  return (
    <BaseCell
      popoverSlot={
        <AttachmentCellPopover isUploading={isUploading} uppy={uppy} attachments={attachments} />
      }
    >
      <DataWithIconCell {...getCellMeta()} />
    </BaseCell>
  );
}

const getMultiProbativeStatus = (
  attachments: Attachment[]
): { pending: number; corrupted: number } =>
  attachments.reduce(
    (acc, attachment) => {
      const { probativeAttachment } = attachment;
      if (probativeAttachment) {
        const { pending, corrupted } = acc;
        if (probativeAttachment.status === 'pending') {
          return { corrupted, pending: pending + 1 };
        }
        if (probativeAttachment.status === 'corrupted') {
          return { pending, corrupted: corrupted + 1 };
        }
      }
      return acc;
    },
    { pending: 0, corrupted: 0 }
  );

const getNoAttachmentStatus = (
  lost: boolean,
  required: boolean,
  intl: IntlShape,
  isUploading: boolean
): {
  type: DataWithIconProps['type'] | undefined;
  title: string;
  icon: ReactNode;
} => {
  if (isUploading) {
    return {
      type: 'info',
      title: intl.formatMessage({
        id: 'transactions.table.attachments.status.title.uploading',
      }),
      icon: <Spinner color="primary-a" />,
    };
  }

  if (lost) {
    return {
      type: 'error',
      title: intl.formatMessage({
        id: 'transactions.table.attachments.status.title.lost',
      }),
      icon: <AttachmentWarningOutlined />,
    };
  }

  if (required) {
    return {
      type: 'info',
      title: intl.formatMessage({
        id: 'transactions.table.attachments.status.title.missing',
      }),
      icon: <AttachmentMissingOutlined />,
    };
  }

  return {
    type: undefined,
    title: intl.formatMessage({
      id: 'transactions.table.attachments.status.not-required',
    }),
    icon: <CheckmarkOutlined />,
  };
};
