import { useEffect, useState, type ReactNode } from 'react';
import { Popup } from '@repo/design-system-kit';
import { useEmberService, useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import window from 'ember-window-mock';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import ENV from 'qonto/config/environment';
import { Illustration } from './illustration';
import styles from './styles.strict-module.css';

const isTest = ENV.environment === 'test';

export const NRC_POPUP_STORAGE_KEY = 'nrcpromopopupdismissed';

const popupDelay = ENV.environment === 'test' ? 0 : 2000;

export function NrcPromotionalPopup(): ReactNode {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const segment = useEmberService('segment');
  const { organization } = useOrganizationManager();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (window.localStorage.getItem(NRC_POPUP_STORAGE_KEY) === '1') {
      return;
    }

    const timeout = setTimeout(() => {
      setIsOpen(true);
      segment.track('agencia-tributaria_modal-pop-up_seen');
      window.localStorage.setItem(NRC_POPUP_STORAGE_KEY, '1');
    }, popupDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [segment]);

  const handleConfirm = (): void => {
    segment.track('agencia-tributaria_modal-pop-up_taxes-clicked');
    void router.push(`/organizations/${organization.slug}/agencia-tributaria`);
  };

  const handleCancel = (): void => {
    segment.track('agencia-tributaria_modal-pop-up_taxes-closed');
    setIsOpen(false);
  };

  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <main> component)
  const TEST_PORTAL_CONTAINER = document.querySelector('main') ?? undefined;

  return (
    <Popup
      title={formatMessage({ id: 'aeat.pop-up.title' })}
      subtitle={formatMessage({ id: 'aeat.pop-up.subtitle' })}
      type="promotional"
      illustration={<Illustration />}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmText={formatMessage({ id: 'aeat.pop-up.cta' })}
      cancelText={formatMessage({ id: 'btn.close' })}
      isOpen={isOpen}
      className={styles.popup}
      UNSTABLE_portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
    >
      <p data-test-id="nrc-promotional-popup-list-header">
        <FormattedMessage id="aeat.pop-up.list-header" />
      </p>
      <ul>
        <li>
          <FormattedMessage id="aeat.pop-up.list-point-1" />
        </li>
        <li>
          <FormattedMessage id="aeat.pop-up.list-point-2" />
        </li>
      </ul>
    </Popup>
  );
}
