import { type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './styles.strict-module.css';

interface InvoiceProps {
  id: string;
  createdAt: string;
  fileName: string;
  selectedInvoiceId: string;
  handleSelectInvoice: (invoiceId: string) => void;
  totalAmount?: number | undefined;
}

export function InvoiceDetailsCard({
  id,
  fileName,
  createdAt,
  totalAmount,
  selectedInvoiceId,
  handleSelectInvoice,
}: InvoiceProps): ReactNode {
  const { locale } = useIntl();
  const { formatMessage } = useIntl();

  const formattedCreatedAt = dateToken({
    date: createdAt,
    locale,
    token: 'date-time-s',
  });

  const isSelected = selectedInvoiceId === id;

  const formattedAmount =
    totalAmount ?? formatMessage({ id: 'receivable-invoices.label.missing-data' });

  return (
    <button
      type="button"
      onClick={() => {
        handleSelectInvoice(id);
      }}
      className={cx(styles.card, isSelected ? styles.selected : styles.unselected)}
      data-test-invoice-details-card-button
    >
      <div className={cx(styles.titleRow)}>
        <span className="body-1" data-test-invoice-details-file-name>
          {fileName}
        </span>
        <span className="body-1" data-test-invoice-details-total-amount>
          {formattedAmount}
        </span>
      </div>
      <div className={cx(styles.date)}>
        <time data-test-invoice-details-date>{formattedCreatedAt}</time>
      </div>
    </button>
  );
}
