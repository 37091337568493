import { type ReactElement } from 'react';
import { Button, CloseIcon, Spinner, Tooltip } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { AttachmentItemBase } from '../attachment-item-base';
import styles from './styles.strict-module.css';

interface AttachmentItemUploadingProps {
  fileName: string;
  progress: number;
  onCancel: () => void;
}

export function AttachmentItemUploading({
  fileName,
  progress,
  onCancel,
}: AttachmentItemUploadingProps): ReactElement {
  const intl = useIntl();

  return (
    <AttachmentItemBase
      icon={<Spinner color="primary-a" />}
      fileName={
        <span className={cx('body-2', styles.file)} data-testid="filename">
          {fileName}
        </span>
      }
      subtitle={
        <span className={cx('caption', styles.uploading)} data-testid="subtitle">
          Uploading - {progress}%
        </span>
      }
      actions={
        <Tooltip
          closeDelay={0}
          delay={400}
          label={intl.formatMessage({
            id: 'transactions.table.attachments.popover.tooltip.delete',
          })}
          tooltipTestId="tooltip"
        >
          <Button
            className={styles.button}
            variant="tertiary"
            iconOnly
            type="button"
            onPress={onCancel}
            data-testid="action-remove"
          >
            <CloseIcon />
          </Button>
        </Tooltip>
      }
      data-testid="attachment-item-uploading"
    />
  );
}
