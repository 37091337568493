import { type ReactNode } from 'react';
import { FormattedNumber } from 'react-intl';
import type { BalanceData } from '../../models/balance';

interface BalanceCellProps {
  balance: BalanceData;
}

export function BalanceCell({ balance }: BalanceCellProps): ReactNode {
  const { amount, isCurrentMonth, type } = balance;

  if (type === 'end' && isCurrentMonth) {
    return <span>-</span>;
  }

  return (
    <span>
      <FormattedNumber value={amount} currency="EUR" style="currency" />
    </span>
  );
}
